import React from 'react';
import '../styles/footer.scss';
import LogoFooter from '../images/TDVBgroupImages/TDVB-logo-white.png';

const FooterPage = () => {
  return (
    <footer className="footer">
      <div className="info-footer-container">
        <div className="logo-footer">
          <img src={LogoFooter}></img>
        </div>

        <div className="address-footer">
          <ul className="info-list-venice">
            <li className="city-name">Venice</li>
            <li className="phone-number"> +39 041 099 2800 </li>
            <li className="address"> Via Torino 186, Mestre </li>
            <li className="email">
              <a href="mailto:info@venicebay.it">info@venicebay.it</a>
            </li>
          </ul>

          <ul className="info-list-prato">
            <li className="city-name">Prato</li>
            <li className="phone-number">+39 0574 020861</li>
            <li className="address">Via Frà Bartolomeo 82/A</li>
            <li className="email">
              <a href="mailto:info@trame-digitali.it">info@trame-digitali.it</a>
            </li>
          </ul>
        </div>

        <div className="services-footer">
          <ul>
            <li className="services">Services</li>
            <li className="skill">Marketing and Strategy</li>
            <li className="skill">Web Development</li>
            <li className="skill">Graphic Design</li>
            <li className="skill">Social Media Management</li>
            <li className="skill">SEO</li>
          </ul>
        </div>
      </div>
      <div className="copyright-privacy-container">
        <p className="copyright">Copyright © 2022 TDVB Group</p>

        <p className="privacy">Privacy policy</p>
      </div>
    </footer>
  );
};

export default FooterPage;
